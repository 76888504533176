import React from 'react';
import PropTypes from 'prop-types';

import SEO from '../../components/SEO';
import Layout from '../../components/Layout';

const TermsAndConditionsData = {
    title: 'Terms and Conditions',
    description: 'Terms and conditions for Follow Your Senses in Tuscany'
};

export const TermsAndConditionsPageTemplate = () => (
    <section className="section">
        <div className="container">
            <div className="content">
                <h1>{TermsAndConditionsData.description}</h1>
                <p>
                    The entity providing online payment services is Autopay S.A
                </p>
                <p>Available forms of payment:</p>
                <div
                    style={{
                        backgroundImage: `url('/img/350x51-pay-only.png')`,
                        height: '51px',
                        width: '350px'
                    }}
                ></div>
                <p>Credit cards:</p>
                <li>Visa</li>
                <li>Visa Electron</li>
                <li>Mastercard</li>
                <li>MasterCardElectronic</li>
                <li>Maestro</li>
                <h2>Return/Refund:</h2>
                <p>
                    1. Complaints If the purchased service does not meet your
                    expectations or has defects, you have the right to file a
                    complaint. To do this, please:
                </p>
                <li>
                    Send a complaint to the e-mail address:
                    followyoursensesintuscany@gmail.com or by mail to the
                    address: Ul.Nad Skawa 14, 34-200 Sucha B. Poland
                </li>
                <li>In the complaint, please provide:</li>
                <li>Name and surname</li>
                <li>Contact details (e-mail address, phone number)</li>
                <li>Order/workshop number</li>
                <li>
                    Description of the problem and date of its occurrence We
                    consider complaints within 14 days of their receipt. We will
                    inform you of the decision by e-mail or phone.
                </li>
                <p>2. Warranty regarding services</p>
                <p>
                    If the service provided by us was performed contrary to the
                    contract or has defects, you have the right to use the
                    warranty. Under the warranty, you may request:
                </p>
                <li>Correction of the service, if possible</li>
                <li>
                    Withdrawal from the contract - in the case of serious
                    defects that prevent the proper use of the service.
                </li>
                <p>Deadline for filing a complaint:</p>
                <li>
                    Complaints regarding the service performed can be filed
                    within 14 days from the date of its performance. In the case
                    of services that are performed continuously or repeatedly,
                    this period is counted from the end of the service
                    provision.
                </li>
                <p>How to file a complaint?</p>
                <p>To file a complaint, please contact us:</p>
                <li>E-mail: followyoursensesintuscany@gmail.com</li>
                <li>
                    Correspondence address: ul.Nad Skawa 14, 24-200 Sucha
                    Beskidzka, Poland
                </li>
                <li>Phone: +48 880-876-412</li>
                <p>Please provide the following in your complaint:</p>
                <li>Name and surname</li>
                <li>Contact details</li>
                <li>Description of the problem</li>
                <li>Date of service</li>
                <p>
                    We will consider the complaint within 14 days of its receipt
                    and inform you of our decision by e-mail or phone.
                </p>
                <p>We are not responsible for defects resulting from:</p>
                <li>Improper use</li>
                <p>3. Service guarantee</p>
                <p>
                    Our services are provided with due diligence and in
                    accordance with the description included in the offer.
                    However, if the service does not meet the terms of the
                    contract, you can file a complaint under the warranty
                    (described above).
                </p>
                <p>
                    Some services may be covered by a quality guarantee, if such
                    has been clearly stated in the offer. The terms of the
                    guarantee may include, among others:
                </p>
                <li>
                    Repeated performance of the service in the event of
                    detection of significant defects
                </li>
                <li>
                    Possibility of using additional support within a specified
                    time
                </li>
                <li>
                    Other benefits specified individually as part of a specific
                    service
                </li>
                <p>
                    Important: The lack of a guarantee for the service does not
                    exclude or limit the consumer's rights resulting from the
                    warranty.
                </p>
                <p>4. Withdrawal from the contract concerning services</p>
                <p>
                    The right to withdraw from the contract concluded at a
                    distance.
                </p>
                <p>
                    In accordance with the Consumer Rights Act, in the case of
                    contracts concluded at a distance (e.g. via the website
                    followtuscany.com) you have the right to withdraw from the
                    contract within 14 days without giving a reason.
                </p>
                <p>
                    To exercise the right to withdraw from the contract, you
                    must send a statement:
                </p>
                <li>By e-mail: followyoursensesintuscany@gmail.com</li>
                <li>
                    By post: ul. Nad Skawa 14, 34-200 Sucha Beskidzka, Poland
                </li>
                <p>Sample statement of withdrawal from the contract:</p>
                <p>[Date]</p>
                <p>[Consumer's name and surname]</p>
                <p>[Consumer's address]</p>
                <p>[Follow Tuscany]</p>
                <p>[Company address]</p>
                <p>Statement of withdrawal from the contract</p>
                <p>
                    I hereby inform you of my withdrawal from the contract
                    regarding the following service:
                </p>
                <p>[Description of the service]</p>
                <p>Date of conclusion of the contract: [date]</p>
                <p>Consumer's name and surname: [name and surname]</p>
                <p>
                    [Consumer's signature] (only if the form is sent in paper
                    form)
                </p>
                <p>
                    After receiving the statement, the payment will be refunded
                    within 14 days from the date of withdrawal from the
                    contract.
                </p>
                <p>Exceptions to the right to withdraw from the contract</p>
                <p>The right to withdraw does not apply in the case of:</p>
                <li>
                    Services that have been fully performed with the express
                    consent of the consumer, if before the commencement of the
                    performance he was informed that he would lose the right to
                    withdraw after the service was performed.
                </li>
                <li>
                    Services related to accommodation, transport, leisure,
                    catering or cultural events, if the contract specifies a
                    specific day or period of service provision.
                </li>
                <li>
                    Personalised services tailored to the individual needs of
                    the customer, which cannot be reused.
                </li>
                <p>
                    In cases where the service has already been partially
                    performed, a proportional reduction of the refund is
                    possible in accordance with the value of the part of the
                    service performed.
                </p>
                <p>
                    hese provisions guarantee the transparency of the rules
                    applicable on the followtuscany.com website and are
                    consistent with the provisions of consumer law in Poland and
                    the European Union.
                </p>
                <h2>Contact Info</h2>
                <p>Follow Your Senses K&D Lenart Spolka komandytowa</p>
                <p>Ul. Nad Skawa 14</p>
                <p>34-200 Sucha Beskidzka</p>
                <p>Polska</p>
                <p>NIP: 5521740593</p>
                <p>Regon: 540565014</p>
                <p>+48880876413</p>
                <p>+393455809974</p>
            </div>
        </div>
    </section>
);

TermsAndConditionsPageTemplate.propTypes = {
    title: PropTypes.string.isRequired
};

const TermsAndConditionsPage = () => {
    return (
        <Layout>
            <SEO
                title={TermsAndConditionsData.title}
                description={TermsAndConditionsData.description}
            />
            <TermsAndConditionsPageTemplate
                title={TermsAndConditionsData.title}
            />
        </Layout>
    );
};

TermsAndConditionsPage.propTypes = {
    data: PropTypes.object.isRequired
};

export default TermsAndConditionsPage;
